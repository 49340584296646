import React from 'react';
import './App.css';
import constructionGif from './construction.gif'; // Ensure this file is in the src folder

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Oops! We're Not Ready Yet!</h1>
        <p>Our developers are working hard...</p>
        <img src={constructionGif} alt="Funny Construction GIF" className="construction-gif" />
      </header>
    </div>
  );
}

export default App;
